<template>
  <div class="card">
    <div class="card-header d-flex">
      <h3 class="card-title">Workflows</h3>
      <router-link
        class="ms-auto btn btn-outline btn-sm"
        to="/reputation/workflow/new"
      >
        New Workflow
      </router-link>
    </div>
    <div v-if="loaded">
      <div class="row" v-if="workflows.length > 0">
        <div class="col-8">
          <div class="table-responsive">
            <table class="table card-table table-vcenter text-nowrap datatable">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Steps</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="workflow in workflows" :key="workflow.id">
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler me-1 icon-tabler-exchange"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="1"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <circle cx="5" cy="18" r="2"></circle>
                      <circle cx="19" cy="6" r="2"></circle>
                      <path d="M19 8v5a5 5 0 0 1 -5 5h-3l3 -3m0 6l-3 -3"></path>
                      <path d="M5 16v-5a5 5 0 0 1 5 -5h3l-3 -3m0 6l3 -3"></path>
                    </svg>
                    <router-link :to="`/reputation/workflow/${workflow.id}`">
                      {{ workflow.name }}
                    </router-link>
                  </td>
                  <td>
                    {{ workflow.raw_machine.workflow.length }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-4">
          <div class="empty">
            <div class="empty-img">
              <img
                src="@/assets/illustrations/undraw_feedback.svg"
                alt=""
                height="128"
              />
            </div>

            <p class="empty-title">Get reviews, automatically</p>

            <p class="empty-subtitle text-muted">
              Put your workflows to work. Add your customers to a survey to
              start earning reviews.
            </p>
            <div class="empty-action">
              <router-link
                :to="`/reputation/review-flows`"
                class="btn btn-primary"
              >
                Surveys
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="empty" v-else>
        <div class="empty-img">
          <img
            src="@/assets/illustrations/undraw_Completed_steps.svg"
            alt=""
            height="128"
          />
        </div>
        <p class="empty-title">No workflows yet</p>
        <p class="empty-subtitle text-muted">
          Create a workflow to guide your customers through an automated process
          to leaving a review.
        </p>
        <div class="empty-action">
          <router-link :to="`/reputation/workflow/new`" class="btn btn-primary">
            Create a workflow
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReputationManagementService from "../../services/ReputationManagementService";

export default {
  name: "reputation.workflows",
  data: () => {
    return {
      workflows: [],
      loaded: false,
    };
  },
  methods: {
    async reloadWorkflows() {
      this.workflows = await ReputationManagementService.getWorkflows(
        this.$auth.activeCompany
      );
      this.loaded = true;
    },
  },
  async mounted() {
    await this.reloadWorkflows();
  },
};
</script>
